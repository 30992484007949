import React from "react";
import { Row, Col } from "react-bootstrap";
import Footer from "./Footer";

export default function Consulting(pros) {

    return (

        <React.Fragment>

            <div id="main-content">
                <Row className="text-center mt-5">
                    <Col>
                        <h1>Consulting Services</h1>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <p>
                            Not sure what technology solutions are best for you? As a  experience software engineer, 
                            I can help assess your current systems and make recommendations for improvements and cost savings.
                            I offer a range of technology solutions to help businesses and individuals bring their technology vision to life.
                            Whether you need help with a specific project or ongoing support for your technology needs, I'm here to help.
                        </p>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col>
                        <h3>Services Provided</h3>
                        <ul>
                            <li>Software Development</li>
                            <li>Automation</li>
                            <li>Testing</li>
                            <li>Migration Services</li>
                            <li>Maintenance & Support</li>
                            <li>Performance Optimization</li>
                            <li>Technical Consulting</li>
                        </ul>
                    </Col>
                </Row>

                <Row className="justify-content-md-center ">
                    <Col sm>
                        <div id="footer">
                            <Footer />
                        </div>
                    </Col>
                </Row>

            </div>
        </React.Fragment>

    );
}