import React from "react";
import { Row, Col } from "react-bootstrap";
import Footer from "./Footer";

export default function SoftwareDevelopment(pros) {

    return (

        <React.Fragment>

            <div id="main-content">
                <Row className="text-center mt-5">
                    <Col>
                        <h1>Software Development Services</h1>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <p>
                            Are you looking for customized solutions that are not available by off-the-shelves products for your business needs? 
                            Custom software solutions can help businesses reduce cost, improve efficiency, and meet unique requirements.
                            I can develop custom software solutions tailored to your business needs and create real business value and 
                            reduce your cost.
                            
                        </p>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col>
                        <h3>Services Provided</h3>
                        <ul>
                            <li>Custom Tool Development</li>
                            <li>Application Development</li>
                            <li>Software Development</li>
                            <li>API Development</li>
                            <li>Web Development</li>
                            <li>Front-End Development</li>
                            <li>Back-End Development</li>  
                            <li>Automation</li> 
                            <li>Software Testing</li> 
                        </ul>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col>
                        <h3>Why Your Business Needs Custom Software Solutions</h3>
                        <ul>
                            <li>Improved Process Automation: Custom software solutions can automate repetitive or manual processes, freeing up time for more important tasks and increasing efficiency.</li>
                            <li>Competitive Advantage: By developing custom software solutions, businesses can differentiate themselves from competitors and gain a competitive advantage in their industry</li>
                            <li>Increased Productivity: Custom software solutions can streamline processes and improve collaboration, leading to increased productivity and better outcomes.</li>

                        </ul>
                    </Col>
                </Row>

                <Row className="justify-content-md-center ">
                    <Col sm>
                        <div id="footer">
                            <Footer />
                        </div>
                    </Col>
                </Row>

            </div>
        </React.Fragment>

    );
}