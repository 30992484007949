import React from "react";
import { Row, Col } from "react-bootstrap";
import Footer from "./Footer";

export default function Training(pros) {

    return (

        <React.Fragment>

            <div id="main-content">
                <Row className="text-center mt-5">
                    <Col>
                        <h1>Training Services</h1>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <p>
                            Are you looking to improve your skills? As a seasoned software engineer with twenty plus years of
                            experience, I offer private one-on-one programming sessions and small group sessions to help
                            individuals to achieve their goals. Whether you're a beginner just starting out or an experienced programmer 
                            looking to brush up your skills, I can provide personalized and tailored programming sessions to meet your 
                            specific needs and help you achieve your goals.
                        </p>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col>
                        <h3>Primary Subjects</h3>
                        <ul>
                            <li>Java Programming</li>
                            <li>Application Development</li>
                            <li>Web Development</li>
                            <li>Agile and Scrum</li>
                            <li>Software architecture concepts</li>
                        </ul>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col>
                        <h3>What You Can Expect:</h3>
                        <ul>
                            <li>Customized lessons tailored to your specific needs and skill level</li>
                            <li>
                                One-on-one attention and support from a knowledgeable and experienced software engineer
                            </li>
                            <li>Hands-on programming practice and feedback to help you improve your skills</li>
                            <li>Hands-on real-world programming challenges</li>
                            <li>Personalized feedback and guidance to help you overcome obstacles and achieve your goals</li>
                        </ul>
                    </Col>
                </Row>


                <Row className="justify-content-md-center ">
                    <Col sm>
                        <div id="footer">
                            <Footer />
                        </div>
                    </Col>
                </Row>

            </div>
        </React.Fragment>

    );
}