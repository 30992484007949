import React from "react";
import { Row, Col } from "react-bootstrap";
import Footer from "./Footer";
import Table from 'react-bootstrap/Table';

export default function CloudComputing(pros) {

    return (

        <React.Fragment>

            <div id="main-content">
                <Row className="text-center mt-5">
                    <Col>
                        <h1>Cloud Computing</h1>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <p>
                            Do you want to migrate your existing applications to cloud or create a new application on cloud? You are at the right place. I work with clients to understand their business needs and recommend the best cloud computing solution to meet their needs.
                        </p>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col>
                        <h3>Why Your Business Needs Cloud Computing Solutions</h3>
                        <ul>
                            <li>Increased Efficiency: Cloud computing allows employees to access business data from anywhere, at any time, on any device. This increases productivity and reduces downtime.</li>
                            <li>Scalability: Cloud computing solutions offer the ability to scale up or down as your business needs change, without having to invest in additional hardware.</li>
                            <li>Cost Savings: By using cloud computing solutions, businesses can save on the costs associated with maintaining and upgrading their own IT infrastructure.</li>
                            <li>Enhanced Security: Cloud computing providers offer robust security measures to ensure that business data is protected from unauthorized access, theft, or loss.</li>
                            <li>Disaster Recovery: In the event of a natural disaster or other emergency, cloud computing solutions ensure that business data is backed up and can be quickly restored.</li>

                        </ul>
                    </Col>
                </Row>
                <Row className="text-center mt-5">
                    <Col>
                        <h1>Serverless</h1>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <h3>What is Serverless?</h3>
                        <p>
                            Serverless is a cloud-native development model that allows developers to build and run applications without having to manage servers.
                            There are still servers in serverless, but they are abstracted away from application development and cloud provider manages the infrastructure and dynamically allocates resources as needed to run the customer's
                            applications without the customer having to manage and operate servers.
                        </p>

                        <h3>What are benefits of Serverless?</h3>
                        <ul>
                            <li>Reducing cost: Eliminates the need to pay for idle resources. Instead, only pay for what is actually used, making it a cost-effective solution.</li>
                            <li>Quick deployments: Serverless computing makes it easier to deploy, update, and manage applications, enabling businesses to respond quickly to changing business requirements.</li>
                            <li>Scalability: Can automatically scale based on demand, allowing applications to handle spikes in traffic without the need for manual intervention. </li>
                            <li>Decreasing latency: Serverless computing allows you to deploy your application to multiple geographic locations, allowing users to access it from the location closest to them.</li>
                            <li>Enhanced Security: All the security managed by the cloud provider and removing the need for organizations to manage and secure their own infrastructure.</li>                  
                        </ul>
                        <p>
                            This website itself is created with serverless stack and hosted on AWS platform. Let's look at the technologies that are used to create this
                            website and the ongoing cost.
                            <ul>
                                <li>This web site is created with React.js</li>
                                <li>React Router to make the website single page application</li>
                                <li>React Bootstrap 5 for CSS styling</li>
                                <li>API Gateway & Lambda for serverless API.</li>
                                <li>DynamoDB for backend.</li>
                                <li>S3 for file uploads and storage.</li>
                                <li>AWS Amplify for hosting the website and automating the frontend build and deployment.</li>
                                <li>Cognito for user authentication and securing the APIs.</li>
                                <li>Route 53 for domain management.</li>
                                <li>Certificate Manager for SSL.</li>
                                <li>Codepipeline for automating backend build and deployment.</li>
                            </ul>
                        </p>

                        <p>
                            The following table shows how much it costs me to run this website on AWS per month. It is very inexpensive because I am only paying per requests, and if there
                            are no requests, there is no charge except Route53 hosted zone.
                        </p>
                        <Table responsive bordered condensed hover>
                            <thead>
                                <tr>
                                    <th>Service</th>
                                    <th>Rate</th>
                                    <th>Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Route53</td>
                                    <td>$0.50 per hosted zone + $0.40 per 1 million queries</td>
                                    <td>$0.50</td>
                                </tr>
                                <tr>
                                    <td>Lambda</td>
                                    <td>Free 1M requests</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td>API Gateway</td>
                                    <td>$3.50/million requests - first 333 million requests/month</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td>DynamoDB</td>
                                    <td>$0.25 per million read request units and $1.25 per million write request units</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td>S3</td>
                                    <td>$0.023 per GB storage used, $0.005 per 1,000 PUT, COPY, POST, or LIST requests, $0.004 per 10,000 GET and all other requests</td>
                                    <td>$0.01</td>
                                </tr>
                                <tr>
                                    <td>Certificate Manager</td>
                                    <td>Free</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td>Cognito</td>
                                    <td>Free</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td>Amplify</td>
                                    <td>1000 build minutes per month free. $0.01 per build minute afterwards.</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td>CloudWatch</td>
                                    <td>First 5GB per month of logs storage is free.</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td>CodePipeline</td>
                                    <td>$0.00 for first active pipeline. $1.00 per active pipeline per month.</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td>US Sales Tax</td>
                                    <td></td>
                                    <td>$0.04</td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td>$0.55</td>
                                </tr>
                            </tbody>
                        </Table>
                        <p>
                            As you can see from the above break down, my total cost is $0.54 for running my serverless application on AWS. This is a lot cheaper compare to
                            if I were hosting it on a web hosting provider like GoDaddy, Bluehost or SiteGround.
                        </p>
                    </Col>
                </Row>

                <Row className="justify-content-md-center ">
                    <Col sm>
                        <div id="footer">
                            <Footer />
                        </div>
                    </Col>
                </Row>

            </div>
        </React.Fragment>

    );
}