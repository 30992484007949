import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import About from "./containers/About";
import ScrollToTop from "./components/ScrollToTop"
import Training from "./containers/Training";
import Coaching from "./containers/Coaching";
import Consulting from "./containers/Consulting";
import SoftwareDevelopment from "./containers/SoftwareDevelopment";
import CloudComputing from "./containers/CloudComputing";

export default function Routes() {
    return (
      <ScrollToTop>
      <Switch>
        <Route path="/" exact component={Home}  />
        <Route path="/index.html" exact component={Home} />
        <Route path="**/index.html" exact component={Home}  />
        <Route path="/home" exact component={Home}  />
        <Route path="/about" exact component={About}  />
        <Route path="/training" exact component={Training}  />
        <Route path="/coaching" exact component={Coaching}  />
        <Route path="/consulting" exact component={Consulting}  />
        <Route path="/softwareDevelopment" exact component={SoftwareDevelopment}  />
        <Route path="/cloudComputing" exact component={CloudComputing}  />
        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />
      </Switch>
      </ScrollToTop>
    );
  }