import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'

export default function Footer() {

  return (
    <React.Fragment>
      <footer>
        <hr />
        <p className="text-center">
          &copy; {new Date().getFullYear()} thatsthecode. All rights reserved.
        </p>
        {/* <a href="https://www.linkedin.com/in/nevzatpinar/"><FontAwesomeIcon icon={faLinkedin} /></a>
        <a href="https://github.com/npinar"><FontAwesomeIcon icon={faGithub} /></a> */}
      </footer>

    </React.Fragment>
  )

}
