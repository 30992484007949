import React from "react";
import { Row, Col } from "react-bootstrap";
import logo from '../images/skills-1248059_640.jpg'
import Footer from "./Footer";

export default function Home(props) {

  return (

    <React.Fragment>

      <div id="main-content">

        <Row className="justify-content-md-center">
          <Col sm={8}>
            <h2>Your Single Source Technology Solutions Provider</h2>
            <h3>That's the code, where I empower individuals to improve their programming skills and teach them how to program from scratch. Whether you're a beginner just starting out or an
              experienced programmer looking to brush up your skills, that's the code can provide personalized and tailored programming sessions to meet your specific needs and help you achieve your goals.
              Additionally, I also offer software development services to help individuals and businesses build custom 
              software solutions tailored to their specific needs.
              Contact me today to learn more!</h3>
          </Col>
          <Col sm={4}>
            <div className="hero-img">
              <img src={logo} className="img-fluid" alt="" />
            </div>
          </Col>
        </Row>

        <div id="section" >
          <Row className="justify-content-md-center first-row">

            <Col sm>
              <div className="icon-box">
                <h4 className="title"><a href="/training">Training</a></h4>
                <p className="description">Offering individual and small group sessions to enhance skills, leading to growth and success in individuals' tech career.</p>
              </div>
            </Col>
            <Col sm>
              <div className="icon-box">
                <h4 className="title"><a href="/coaching">Coaching</a></h4>
                <p className="description">I offer personalized coaching sessions to guide individuals in discovering their career path within information technology along with preparing for technical interviews, and refining their resumes to showcase their skills and experience to potential employers.</p>
              </div>
            </Col>
            <Col sm>
              <div className="icon-box">
                <h4 className="title"><a href="/consulting">Consulting</a></h4>
                <p className="description">Whether you need help with a specific project or ongoing support for your technology needs, I'm here to help. Contact me today to help you with your challenges.</p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-md-center row-gap">
            <Col sm={{ span: 3, offset: 0.5 }}>
              <div className="icon-box">
                <h4 className="title"><a href="/softwareDevelopment">Software Development</a></h4>
                <p className="description">Creating custom softwares that is tailored to specific needs and requirements of my clients, providing advanced functionality and a competitive edge.</p>
              </div>
            </Col>
            <Col sm={{ span: 3 }}>
              <div className="icon-box">
                <h4 className="title"><a href="/webDevelopment">Web Development</a></h4>
                <p className="description">My goal is to construct user-friendly websites utilizing the latest technologies that provide both efficiency and cost-effectiveness.</p>
              </div>
            </Col>
            <Col sm={{ span: 3 }}>
              <div className="icon-box">
                <h4 className="title"><a href="/cloudComputing">Cloud Computing</a></h4>
                <p className="description">I can help you start your cloud journey or streamline and optimize your current cloud platform to deliver cost savings and efficiencies.</p>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="justify-content-md-center ">
            <Col sm>
              <div id="footer">
                <Footer />
              </div>
            </Col>
          </Row>
      
      </div>
      
    </React.Fragment>

  );
}