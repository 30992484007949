import React from "react";
import { Row, Col } from "react-bootstrap";
import Footer from "./Footer";

export default function Coaching(pros) {

    return (

        <React.Fragment>

            <div id="main-content">
                <Row className="text-center mt-5">
                    <Col>
                        <h1>Coaching Services</h1>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <p>
                            As a software engineer, I understand the challenges of navigating the rapidly evolving technology landscape. 
                            I offer personalized coaching sessions to help individuals find their passion and build a successful career in information technology.

                        </p>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col>
                        <h3>Take control of your career and maximize your chances of success with my expert coaching and guidance. </h3>
                        <ul>
                            <li>Career Path Planning</li>
                            <li>Technology Stack Assessment</li>
                            <li>Resume & Cover Letter Review</li>
                            <li>Technical Interview Preparation</li>
                            <li>Behavioral Interview Preparation</li>
                            <li>Mock Interviews</li>
                        </ul>
                    </Col>
                </Row>

                <Row className="justify-content-md-center ">
                    <Col sm>
                        <div id="footer">
                            <Footer />
                        </div>
                    </Col>
                </Row>

            </div>
        </React.Fragment>

    );
}