import React, { useState } from "react";
import "./App.css";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

function App(props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (

    <React.Fragment>
    <div className="App container">
      <Navbar fluid bg="light" expand="lg" sticky="top" collapseOnSelect>
        <Navbar.Brand>
          <Link to="/">that's the code</Link>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="ms-auto">
            <LinkContainer to="/home">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <NavDropdown
              title="Services" id="basic-nav-dropdown"
              onMouseEnter={() => setIsDropdownOpen(true)}
              onMouseLeave={() => setIsDropdownOpen(false)}
              show={isDropdownOpen}
            >
              <NavDropdown.Item href="/training">Training</NavDropdown.Item>
              <NavDropdown.Item href="/coaching">Coaching</NavDropdown.Item>
              <NavDropdown.Item href="/consulting">Consulting</NavDropdown.Item>
              <NavDropdown.Divider />
                <NavDropdown.Item href="/softwareDevelopment">Software Development</NavDropdown.Item>
                <NavDropdown.Item href="/cloudComputing">Cloud Computing</NavDropdown.Item>
            </NavDropdown>
            <LinkContainer to="/about">
              <Nav.Link>About</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Routes />

    </div>
    </React.Fragment>
    
  );
}

export default withRouter(App);