import React from 'react';
import { Row, Col } from "react-bootstrap";
import Footer from "./Footer";
import profile from '../profile.json'

export default function About(props) {

  return (
    <React.Fragment>

      <div id="main-content">

        <Row className="justify-content-md-center">
          <Col sm={6}> <h2>{profile.title}</h2> </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={6}> <h3>{profile.summary1}</h3> </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={6}>  <h3>{profile.summary2}</h3></Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={6}> <h3>{profile.summary3}</h3> </Col>
        </Row>
        
        {/* <Row className="justify-content-md-center">
          <Col sm> <h3>Profile</h3></Col>
        </Row>
        <Row className="justify-content-md-center">
          <div className='about'>
            <Col sm> <div className="LI-profile-badge" data-version="v1" data-size="medium" data-locale="en_US" data-type="horizontal" data-theme="light" data-vanity="nevzatpinar">
              <a class="LI-simple-link" href='https://www.linkedin.com/in/nevzatpinar?trk=profile-badge'>Nevzat Ugur Pinar</a>
            </div></Col>
          </div>
        </Row> */}


        <Row className="justify-content-md-center">

          <Col sm>
            <div id="footer">
              <Footer />
            </div>
          </Col>

        </Row>

      </div>


    </React.Fragment>
  );

}